'use strict';

angular.module('managerApp').directive('dragDrop', function () {
    return {
        link: function (scope, element) {
            element.attr('draggable', 'true');
            element.on('dragover', function (e) {                
                this.classList.add('dropping');
                // Prevent default behavior (Prevent file from being opened)
                e.preventDefault();
            });
            element.on('drop', function (e) {
                console.log('File(s) dropped');

                // Prevent default behavior (Prevent file from being opened)
                e.preventDefault();

                if (e.dataTransfer.items) {
                    
                    // Use DataTransferItemList interface to access the file(s)
                    [...e.dataTransfer.items].forEach((item, i) => {
                        // If dropped items aren't files, reject them
                        
                        console.log('----')
                        console.log(item)
                        
                        if (item.kind === 'file') {
                            const file = item.getAsFile();
                            console.log(`1 file[${i}].name = ${file.name}`);
                        }
                    });
                    
                } else {
                    // Use DataTransfer interface to access the file(s)
                    [...e.dataTransfer.files].forEach((file, i) => {
                        console.log(`2 file[${i}].name = ${file.name}`);
                    });
                    
                    console.log('2222222222222222222222222222');
                    
                }
            });
            element.on('dragstart', function (event) {
                scope.$apply();
            });
        }

    };
});